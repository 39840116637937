import { Controller } from 'stimulus';
import Swiper, {Pagination} from 'swiper'
import throttle from 'lodash/throttle'

Swiper.use([Pagination])

export default class extends Controller {
  static targets = ['bg']

  connect() {
    // Handle the window resize event
    // this will destroy the slider if it exists,
    // and then re-initialize it based on the window width
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', throttle(this.handleResize, 250))
    this.initSlider();
    document.addEventListener("DOMContentLoaded", this.lazyLoadBG.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', throttle(this.handleResize, 250))
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
  }

  /**
   * Handle the window resize event -
   * This will destroy the slider if it exists, and then re-initialize it.
   * @returns {void}
   */
  handleResize() {
    // if the slider exists, destroy it
    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
    // re-initialize the slider
    this.initSlider()
  }

  /**
   * Initialize the slider -
   * This will initialize the slider based on the window width
   */
  initSlider() {
    // get the window width
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    // if the window width is less than 700px, initialize the slider
    if (windowWidth < 700) {
      this.initCinemaSlider()
    }
  }

  /**
   * Initialize the cinema slider -
   * We will show a slider for the gallery images
   */
  initCinemaSlider() {
    this.swiper = new Swiper('.js-cinemaSlider', {
      slidesPerView: 1.1,
      speed: 400,
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  }

  lazyLoadBG() {
    if ("IntersectionObserver" in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            entry.target.style.backgroundImage = `url('${entry.target.dataset.image}')`
            lazyBackgroundObserver.unobserve(entry.target)
          }
        });
      });
      lazyBackgroundObserver.observe(this.bgTarget)
    }
  }
}
